import React from 'react'
import {createClient} from 'contentful'
  

const client = createClient({
    space: 'pyjzth4yg174',
    accessToken: 'af80ee7505b068ebba4ea84c83cd243d96f54ea265e4767ba6b2172bdf5b0cf4'
})


const makeDate = ( start_date, end_date ) => {
  const getOrd = (n) => {
    return n + (n > 0 ? ['th', 'st', 'nd', 'rd'][(n > 3 && n < 21) || n % 10 > 3 ? 0 : n % 10] : '');
  }

  const getMonth = (month) => {
    return ["Jan", "Feb", "Mar", "April", "May", 
            "June", "July", "Aug", "Sept", "Oct", 
            "Nov", "Dec"][month]
  }

  if(start_date && end_date) {
    const now = new Date();
    const start = new Date(start_date);
    const end = new Date(end_date);

    return (
      <span className="newslist-date">
        {getMonth(start.getMonth()) + " " + getOrd(start.getDate()) + " " + start.getFullYear() + " - "}
        {getMonth(end.getMonth()) + " " + getOrd(end.getDate()) + " " + end.getFullYear()}
        
        {now > end &&
          <span className="expired"> (expired) </span>
        }
      </span>
    )
  }

  if( start_date ) {
    const start = new Date(start_date);

    return (
      <span className="newslist-date">
        From {getMonth(start.getMonth()) + " " + getOrd(start.getDate()) + " " + start.getFullYear()}
      </span>
    )
  }

  if( end_date ) {
    const now = new Date();
    const end = new Date(end_date);

    return (
      <span className="newslist-date">
        Until {getMonth(end.getMonth()) + " " + getOrd(end.getDate()) + " " + end.getFullYear()}
        
        {now > end &&
          <span className="expired"> (expired) </span>
        }
      </span>
    )
  }
}

const generateList = ( list ) => {
  const createMarkup = ( content ) => {
    return {__html: content.replace(/\n/g, "<br />")}
  }

  return (
    <div>
      {list.map( (item, i) => (
        <div key={i} className="newslist-item">
          <h3 className="newsitem-title">
            {item.node.title}
          </h3>

          {makeDate(item.node.start_date, item.node.end_date)}
          
          <p className="newsitem-content" 
            dangerouslySetInnerHTML={createMarkup(item.node.description.description)}>
          </p>

          { item.node.link &&
            <a href={item.node.link} className="newslist-link" target="_blank">
              {
                item.node.linktext ? 
                item.node.linktext + " →" : "more information →" }
            </a> }
        </div>
      ))}
    </div>
  )
}


class NewsList extends React.Component {
  state = {
    count: this.props.count,
    list: this.props.list.slice(0, this.props.count),
    buttonText: "load more ↓"
  };

  loadItems = () => {
    this.setState({
      buttonText: "loading..."
    })

    let listSlice = this.state.list
    let count = this.state.count + this.props.count 

    client.getEntries({
      'content_type': 'evenement',
      'order': '-fields.end_date',
      'limit': this.props.count,
      'skip': this.state.count
    })
    .then((response) => {
      // change the response format so it matches the format returned by graphQL
      response.items.forEach(element => {
        element.node = element.fields
        element.node.description = {'description': element.node.description}
        listSlice.push(element)
      })
      this.setState({
        count: count,
        list: listSlice,
        buttonText: "load more ↓"
      })
    })
    .catch(console.error)
  }

  render() {
    return (
      <div className="newslist">
        {generateList(this.state.list)}

        { this.state.count < this.props.totalCount &&
          
          <button id="load-more" onClick={this.loadItems}>
            {this.state.buttonText}
          </button>
          
        }
      </div>
    )
  }
}

export default NewsList
