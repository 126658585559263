import React from 'react'
import * as PropTypes from "prop-types"
import { graphql } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image"
import NewsList from '../components/NewsList'
import Layout from "../components/layout"

const propTypes = {
  data: PropTypes.object.isRequired,
}

const Feature = ({ list }) => {
  return (
    <div className="featured">
      <h2 className="section-title"> Featured Item </h2>
      <h3 className="featured-title">{list[0].node.title}</h3>
      <div className="featured-content" 
        dangerouslySetInnerHTML={{__html: list[0].node.childContentfulUitgelichtItemDescriptionTextNode.childMarkdownRemark.html}}>
      </div>
    </div>
  )
}

const RandomImage = ({ list }) => {
  let randomImage = list[Math.floor(Math.random() * list.length)];
  return <GatsbyImage image={randomImage.node.image.gatsbyImageData} alt={randomImage.node.image.title} />
}

const NewsPage = ({ data }) => (
  <Layout>
    <div id="news-wrapper">
      <div id="col-left">
        <RandomImage list={data.allContentfulWillekeurigeAfbeelding.edges} />
      </div>
      
      <div id="col-right">
        <Feature list={data.allContentfulUitgelichtItem.edges}/>
        <h2 className="section-title"> News </h2>
        <NewsList list={data.allContentfulEvenement.edges} 
                  count={5} 
                  totalCount={data.allContentfulEvenement.totalCount}/>
      </div>
    </div>
  </Layout>
)

NewsPage.propTypes = propTypes;

export default NewsPage;

export const query = graphql`
query {
  allContentfulEvenement(
    sort: {fields: [end_date], order:DESC},
    limit: 5
  )
  {
    totalCount
    edges {
      node {
        title
        start_date
        end_date
        link
        linktext
        description {
          description
        }
      }
    }
  }

  allContentfulUitgelichtItem {
    edges {
      node {
        title
        childContentfulUitgelichtItemDescriptionTextNode {
          childMarkdownRemark {
            html
          }
        }
      }
    }
  }

  allContentfulWillekeurigeAfbeelding {
    edges {
      node {
        image {
          title
          gatsbyImageData(
            placeholder: DOMINANT_COLOR
            formats: [AUTO, WEBP, AVIF]
          )
        }
      }
    }
  }
}
`